"use client";
import React, { useState } from "react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

export const IntlProvider = ({ messages, locale, children }) => {
  useState(() => {
    i18n.load(locale, messages);
    i18n.activate(locale);
  });
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};
